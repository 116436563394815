(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.controller:CompanySelectorCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount')
    .controller('CompanySelectorCtrl', CompanySelectorCtrl);

  function CompanySelectorCtrl(companies, FormControl, LoggedUser, $state, _) {
    var vm = this
      , formControl = new FormControl();

    vm.companies = companies;
    vm.selectedCompany = _.first(companies) ? _.first(companies).id : {};

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.submit = function submit() {
      formControl
        .process(LoggedUser.setCompany(vm.selectedCompany))
        .then(function () {
          $state.go('dashboard');
        });
    };
  }
}());
